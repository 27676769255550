import { ReadParameter, P } from "../../business";
import { CalculateIva } from "../../business/CartHelper";

/**
 * Procesar textos para eliminar caracteres extraños
 */
var Latinise = {};
Latinise.latin_map = {
  Á: "A",
  À: "A",
  Ã: "A",
  Ä: "A",
  É: "E",
  È: "E",
  Ë: "E",
  Í: "I",
  Ì: "I",
  Ï: "I",
  Ó: "O",
  Ò: "O",
  Õ: "O",
  Ö: "O",
  Ú: "U",
  Ù: "U",
  Ü: "U",
  Ñ: "N",
  "€": "E",
  á: "a",
  à: "a",
  ã: "a",
  ä: "a",
  é: "e",
  è: "e",
  ë: "e",
  í: "i",
  ì: "i",
  ï: "i",
  ó: "o",
  ò: "o",
  õ: "o",
  ö: "o",
  ú: "u",
  ù: "u",
  ü: "u",
  ñ: "n",
};
String.prototype.latinise = function () {
  return this.replace(/[^A-Za-z0-9\[\] ]/g, function (a) {
    return Latinise.latin_map[a] || a;
  });
};

// ------------------------------------------------------------------

/**
 * Printing utilities
 */

// ESC/POS Commands
const esc = "\x1B"; //ESC byte in hex notation
const gs = "\x1D"; //GS byte in hex notation
const newLine = "\x0A"; //LF byte in hex notation
const cutPaper = esc + "d" + "\x02";
const feed2Lines = esc + "m";
const simpleTab = "  ";
const doubleTab = simpleTab + simpleTab;
const DoubleOn = esc + "!" + "\x20";
const DoubleOff = esc + "!" + "\x00";
const NormalFontSize = esc + "!" + "\x00";
const SmallFontSize = esc + "!" + "\x21";
const TinyFontSize = esc + "!" + "\x03";
const BigFontSize = DoubleOn;
const LargeFontSize = gs + "!" + "\x11";
const ReverseModeOn = gs + "\u0042" + "\x01";
const ReverseModeOff = gs + "\u0042" + "\x00";
const BoldOn = esc + "!" + "\x08";
const BoldOff = esc + "!" + "\x00";
const CenterOn = esc + "a" + "\x01";
const CenterOff = esc + "a" + "\x00";

// Literals
const EURO = "";
const IVA = "IVA (10%) ";
export const DIVIDER = "------------------------------------------";
const PAYMENT_METHOD_CASH = "Efectivo";
const PAYMENT_METHOD_CARD = "Tarjeta";
const PAYMENT_METHOD_STRIPE = "Pagado app";

// ------------------------------------------------------------------

function PrintInvoiceCategory() {
  return ReadParameter(P.print_categories) === "true";
}

function PrintCommandCategory() {
  return ReadParameter(P.print_categories_commands) === "true";
}

function PrintAttributes() {
  return ReadParameter(P.print_attributes) === "true";
}

function PrintComments() {
  return ReadParameter(P.print_comments) === "true";
}

function PrintMenuProducts() {
  return ReadParameter(P.print_menu_products) === "true";
}

function GetFontSize(text) {
  switch (ReadParameter(P.font_size)) {
    case "NORMAL":
      return NormalFontSize + text + NormalFontSize;
    case "BIG":
      return BigFontSize + text + NormalFontSize;
    case "LARGE":
      return LargeFontSize + text + NormalFontSize;
    default:
      return text;
  }
}

function MaxTextLength() {
  const PROD_LENGHT = 23;
  const BIG_PROD_LENGHT = 12;
  const LARGE_PROD_LENGHT = 12;

  switch (ReadParameter(P.font_size)) {
    case "NORMAL":
      return PROD_LENGHT;
    case "BIG":
      return BIG_PROD_LENGHT;
    case "LARGE":
      return LARGE_PROD_LENGHT;
    default:
      return BIG_PROD_LENGHT;
  }
}

function LineHeight() {
  return ReadParameter(P.line_height);
}

function CustomerText() {
  return ReadParameter(P.custom_text);
}

function fontSizeCommands(text) {
  switch (ReadParameter(P.font_size_commands)) {
    case "NORMAL":
      return NormalFontSize + text + NormalFontSize;
    case "BIG":
      return BigFontSize + text + NormalFontSize;
    case "LARGE":
      return LargeFontSize + text + NormalFontSize;
    default:
      return text;
  }
}

function CommandLineHeight() {
  return ReadParameter(P.line_height_commands);
}

function FitToSize(st, ln, mode = "END") {
  if (st.length < ln)
    return mode == "END" ? st.padEnd(ln, " ") : st.padStart(ln, " ");
  else return st.substr(0, ln);
}

function IsNotEmpty(value) {
  return value != null && value != undefined && value != "";
}

// ------------------------------------------------------------------

/**
 * Generates ESCPOS commands for all the products in a category
 * @param {*} category_name
 * @param {*} products
 * @returns
 */
function CategoryProducts(category_name, products) {
  let cmds = "";
  const cat_products = products.filter((p) => p.category === category_name);

  for (let n = 0; n < cat_products.length; n++) {
    cmds += InvoiceProduct(cat_products[n], true);
  }

  return cmds;
}

/**
 * Generates ESCPOS commands to show partial payment invoice footer
 * @param {*} total
 * @param {*} amount
 * @param {*} method
 * @returns
 */
export function InvoicePartialFooter(total, amount, method) {
  let cmds = CenterOn;
  let totalAmt = parseFloat(total + "");
  let toPayAmt = parseFloat(amount + "");
  let payMethod = "Efectivo";

  if (method === "CARD") payMethod = "Tarjeta";
  if (method === "BANK") payMethod = "Banco";

  cmds +=
    FitToSize("Importe total", 31) +
    FitToSize(totalAmt.toFixed(2), 12, "START") +
    EURO;
  cmds += newLine;

  cmds +=
    FitToSize("Importe a pagar", 31) +
    FitToSize(toPayAmt.toFixed(2), 12, "START") +
    EURO;
  cmds += newLine;

  cmds +=
    FitToSize("Metodo de pago", 31) + FitToSize(payMethod, 12, "START") + EURO;
  cmds += newLine;

  cmds += CenterOn + DIVIDER + newLine + CenterOff;

  const formatedTotal = CalculateIva(toPayAmt).toFixed(2);
  const formatedBase = (toPayAmt - CalculateIva(toPayAmt)).toFixed(2);

  cmds += CenterOn;

  cmds +=
    FitToSize("Base imp.", 31) + FitToSize(formatedBase, 12, "START") + EURO;
  cmds += newLine;
  cmds += FitToSize(IVA, 31) + FitToSize(formatedTotal, 12, "START") + EURO;
  cmds += newLine;

  cmds += BoldOn + CenterOn;

  cmds +=
    DIVIDER +
    newLine +
    FitToSize("Importe pagado", 31) +
    FitToSize(toPayAmt.toFixed(2), 12, "START") +
    EURO;

  cmds += BoldOff;
  cmds += newLine + CenterOn + newLine;
  cmds += CustomerText();
  cmds += newLine + newLine;
  cmds += newLine + newLine;
  cmds += newLine + newLine + DoubleOff;
  cmds += feed2Lines;
  cmds += cutPaper;

  return cmds;
}

/**
 * Generates ESCPOS commands for the footer in an invoice
 * @param {number} total
 * @param {*} _order
 * @returns ESCPOS commands for an invoice footer
 */
export function InvoiceFooter(total, _order) {
  let cmds = CenterOn;
  let pending = parseFloat(total?.toString());

  const partial = _order
    ? IsNotEmpty(_order.cash_payment) ||
      IsNotEmpty(_order.card_payment) ||
      IsNotEmpty(_order.stripe_payment)
    : false;

  if (partial) {
    cmds +=
      FitToSize("Importe total", 31) +
      FitToSize(pending.toFixed(2), 12, "START") +
      EURO;
    cmds += newLine;
  }

  if (_order && IsNotEmpty(_order.cash_payment)) {
    const formtCash = parseFloat(_order.cash_payment.toString()).toFixed(2);
    cmds +=
      FitToSize("Pagado efectivo", 31) +
      FitToSize(formtCash, 12, "START") +
      EURO;
    cmds += newLine;
    pending -= parseFloat(_order.cash_payment.toString());
  }

  if (_order && IsNotEmpty(_order.card_payment)) {
    const formtCard = parseFloat(_order.card_payment.toString()).toFixed(2);
    cmds +=
      FitToSize("Pagado tarjeta", 31) +
      FitToSize(formtCard, 12, "START") +
      EURO;
    cmds += newLine;
    pending -= parseFloat(_order.card_payment.toString());
  }

  if (_order && IsNotEmpty(_order.stripe_payment)) {
    const formtApp = parseFloat(_order.stripe_payment.toString()).toFixed(2);
    cmds +=
      FitToSize("Pagado aplicacion", 31) +
      FitToSize(formtApp, 12, "START") +
      EURO;
    cmds += newLine;
    pending -= parseFloat(_order.stripe_payment.toString());
  }

  if (partial) cmds += CenterOn + DIVIDER + newLine + CenterOff;

  const formatedTotal = CalculateIva(parseFloat(pending.toString())).toFixed(2);
  const formatedBase = (
    pending - CalculateIva(parseFloat(pending.toString()))
  ).toFixed(2);
  const formatedPending = parseFloat(pending.toString()).toFixed(2);

  cmds += CenterOn;

  cmds +=
    FitToSize("Base imp.", 31) + FitToSize(formatedBase, 12, "START") + EURO;
  cmds += newLine;

  cmds += FitToSize(IVA, 31) + FitToSize(formatedTotal, 12, "START") + EURO;
  cmds += newLine;

  cmds += BoldOn + CenterOn;

  if (partial)
    cmds +=
      DIVIDER +
      newLine +
      FitToSize("Importe total", 31) +
      FitToSize(formatedPending, 12, "START") +
      EURO;
  else
    cmds +=
      FitToSize("Importe total", 31) +
      FitToSize(formatedPending, 12, "START") +
      EURO;

  cmds += BoldOff;
  cmds += newLine + CenterOn + newLine;
  cmds += CustomerText();
  cmds += newLine + newLine;
  cmds += newLine + newLine;
  cmds += newLine + newLine + DoubleOff;
  cmds += feed2Lines;
  cmds += cutPaper;

  return cmds;
}

export function CommandEscPos(
  date,
  time,
  orderId,
  tipoPedido,
  products,
  _order_data,
  _people_number,
  _comment
) {
  var cmds = esc + "@"; //Initializes the printer (ESC @)
  cmds += esc + "!" + "\x00"; //Character font A selected (ESC ! 0)
  cmds += esc + "t" + "\x10"; // Code page
  cmds += esc + "a" + "\x01"; // Center

  // Order data
  cmds += date + simpleTab + time.substring(0, 8) + newLine;
  if (IsNotEmpty(_order_data)) {
    cmds += newLine + DoubleOn + "#" + orderId + DoubleOff + newLine;
  }

  cmds += newLine;
  cmds += LargeFontSize + tipoPedido.toUpperCase() + NormalFontSize;
  if (_people_number && !IsNotEmpty(_order_data))
    cmds +=
      doubleTab + LargeFontSize + _people_number + " PERS" + NormalFontSize;

  // External orders
  if (
    IsNotEmpty(_order_data) &&
    _order_data.hour != undefined &&
    _order_data.hour != null
  ) {
    cmds += LargeFontSize + " " + _order_data.hour + " " + NormalFontSize;
    cmds += newLine;
  }

  cmds += newLine;
  cmds += esc + "a" + "\x00";

  if (_comment) {
    cmds += DoubleOn + "COMENTARIO: " + DoubleOff + _comment;
    cmds += newLine;
    cmds += newLine;
  }
  cmds += esc + "a" + "\x01";
  cmds += DIVIDER;
  cmds += newLine;
  cmds += esc + "a" + "\x00";
  cmds += newLine;

  // Products
  let printed_categories = [];
  for (let n = 0; n < products.length; n++) {
    const p = products[n];

    if (
      p.category != null &&
      p.category != undefined &&
      printed_categories.findIndex((pc) => pc == p.category) == -1 &&
      PrintCommandCategory()
    ) {
      cmds +=
        DoubleOn +
        ReverseModeOn +
        " " +
        ReverseModeOff +
        " " +
        p.category.latinise() +
        DoubleOff;
      cmds += newLine + newLine;
      cmds += CommandProduct(p, true);
      cmds += CommandCategoryProducts(
        p.category,
        products.slice(n + 1, products.length)
      );
      printed_categories.push(p.category);
      cmds += newLine;
    } else if (printed_categories.findIndex((pc) => pc == p.category) == -1) {
      cmds += CommandProduct(p, true);
    }
  }

  cmds += esc + "a" + "\x01";
  cmds += DIVIDER;
  cmds += newLine;
  cmds += esc + "a" + "\x00";
  cmds += newLine + newLine;
  cmds += newLine + newLine + DoubleOff;
  cmds += feed2Lines; // Feed 2 lines
  cmds += cutPaper; // Cut

  return cmds;
}

/**
 * Generates ESCPOS commands for a product in an invoice
 * @param {Object} product The object of the product
 * @param {boolean} printPrice
 * @returns ESCPOS commands for printing a product
 */
export function CommandProduct(product) {
  let cmds = "";

  if (product == null || product == undefined) return cmds;

  // Name
  let name = product.name.toUpperCase().latinise();

  // Atributes
  const amount = product.amount;
  const observation = product.observation;
  const attributes = product.attributes;
  const edited = product.edited;
  const cancelled = product.cancelled;

  // Comands

  if (cancelled) {
    cmds += esc + "a" + "\x01";
    cmds += DoubleOn + ">>> CANCELAR <<<" + DoubleOff;
    cmds += newLine;
    cmds += esc + "a" + "\x00";
    cmds += newLine;
  } else if (edited) {
    cmds += esc + "a" + "\x01";
    cmds += DoubleOn + ">>> EDITAR <<<" + DoubleOff;
    cmds += newLine;
    cmds += esc + "a" + "\x00";
    cmds += newLine;
  }

  cmds += fontSizeCommands(amount) + " " + fontSizeCommands(name);

  cmds += newLine;

  if (IsNotEmpty(product.menu_id) && IsNotEmpty(product.menu_name)) {
    cmds += simpleTab;
    cmds += "* ";
    cmds +=
      DoubleOn + "M: " + DoubleOff + product.menu_name.latinise() + newLine;
  }

  if (IsNotEmpty(observation)) {
    cmds +=
      fontSizeCommands('  "') +
      fontSizeCommands(observation.latinise()) +
      fontSizeCommands('"');
    cmds += newLine;
  }

  if (IsNotEmpty(attributes)) {
    cmds +=
      fontSizeCommands("  *") +
      fontSizeCommands(attributes.replaceAll(",", newLine + "  ").latinise());
    cmds += newLine;
  }

  // Line height
  const lHeight = parseInt(CommandLineHeight());
  for (let w = 0; w < lHeight; w++) {
    cmds += newLine;
  }

  return cmds;
}

/**
 * Generates ESCPOS commands for all the products in a category
 * @param {*} category_name
 * @param {*} products
 * @returns
 */
function CommandCategoryProducts(category_name, products) {
  let cmds = "";
  const cat_products = products.filter((p) => p.category === category_name);

  for (let n = 0; n < cat_products.length; n++) {
    cmds += CommandProduct(cat_products[n]);
  }

  return cmds;
}

/**
 *
 * @param {*} datetime
 * @param {*} type
 * @param {*} products
 * @returns
 */
export function ExternalOrderSummary(datetime, type, products) {
  var cmds = esc + "@"; //Initializes the printer (ESC @)
  cmds += esc + "!" + "\x00"; //Character font A selected (ESC ! 0)
  cmds += esc + "t" + "\x02";

  // Obtener datos del negocio
  const name = ReadParameter(P.name);
  cmds += esc + "a" + "\x01";
  cmds += DoubleOn + name.toUpperCase() + DoubleOff;
  cmds += newLine;
  cmds += datetime;
  cmds += newLine + newLine;
  cmds += LargeFontSize + "RESUMEN " + type + NormalFontSize;
  cmds += newLine + DIVIDER + newLine;
  cmds += esc + "a" + "\x00";
  cmds += newLine;

  // Products
  let printed_categories = [];
  for (let n = 0; n < products.length; n++) {
    const p = products[n];

    if (
      p.category != null &&
      p.category != undefined &&
      printed_categories.findIndex((pc) => pc == p.category) == -1 &&
      PrintCommandCategory()
    ) {
      cmds +=
        DoubleOn +
        ReverseModeOn +
        " " +
        ReverseModeOff +
        " " +
        p.category.latinise() +
        DoubleOff;
      cmds += newLine + newLine;
      cmds += CommandProduct(p, true);
      cmds += CommandCategoryProducts(
        p.category,
        products.slice(n + 1, products.length)
      );
      printed_categories.push(p.category);
      cmds += newLine;
    } else if (printed_categories.findIndex((pc) => pc == p.category) == -1) {
      cmds += CommandProduct(p, true);
    }
  }

  cmds += esc + "a" + "\x01";
  cmds += DIVIDER;
  cmds += newLine;
  cmds += esc + "a" + "\x00";
  cmds += newLine + newLine;
  cmds += newLine + newLine + DoubleOff;
  cmds += feed2Lines; // Feed 2 lines
  cmds += cutPaper; // Cut

  return cmds;
}

/**
 *
 * @param {*} date
 * @param {*} time
 * @param {*} invoiceId
 * @param {*} internalId
 * @param {*} type
 * @param {*} peopleNumber
 * @param {*} customer
 * @param {*} orderData
 * @returns
 */
function InvoiceHeaderESCPOS(
  date,
  time,
  invoiceId,
  internalId,
  type,
  peopleNumber,
  customer,
  orderData
) {
  //>> Business legal data
  const name = ReadParameter(P.name);
  const legal_name = ReadParameter(P.legal_name);
  const phone = ReadParameter(P.phone);
  const cif = ReadParameter(P.cif);
  const address = ReadParameter(P.address);
  const city = ReadParameter(P.city);
  const zip_code = ReadParameter(P.zip_code);

  let cmds = "";
  cmds += CenterOn + DoubleOn;
  cmds += name.latinise().toUpperCase() + DoubleOff;
  cmds += newLine;
  if (legal_name) cmds += legal_name + newLine;

  cmds += address.latinise();
  if (zip_code + city)
    cmds += newLine + (city || "") + " - " + zip_code + newLine;
  else cmds += newLine;
  cmds += "CIF: " + cif + "   ";
  cmds += "TEL: " + phone + newLine;

  cmds += CenterOn + DIVIDER + newLine + CenterOff;

  //>> Named invoices
  if (customer != undefined && customer != null) {
    const c_city = customer.city;
    const c_zip_code = customer.zip_code;

    cmds += "Razon social: " + customer.name + newLine;
    if (customer.cif) cmds += "CIF/NIF: " + customer.cif + newLine;
    if (customer.address) cmds += customer.address + newLine;
    if (c_city + c_zip_code) cmds += c_zip_code + " - " + c_city + newLine;
    if (customer.phone) cmds += "TEL: " + customer.phone + newLine;
    // if (customer.email) cmds += "EMAIL: " + customer.email + newLine;

    cmds += CenterOn + DIVIDER + newLine + CenterOff;
  }

  //>> External orders: DEL / TAK
  if (orderData != undefined && orderData != null) {
    cmds += CenterOn;

    cmds += DoubleOn + "#" + internalId + DoubleOff + newLine;
    cmds += DoubleOn + type.toUpperCase();
    if (IsNotEmpty(orderData.hour)) cmds += ": " + orderData.hour;

    cmds += DoubleOff + newLine + CenterOn + DIVIDER + newLine;
    if (IsNotEmpty(orderData.external_order_id)) {
      cmds +=
        BoldOn +
        "PAGADO VIA TPV VIRTUAL" +
        simpleTab +
        orderData.external_order_id +
        BoldOff +
        newLine;
    }
    switch (orderData.payment) {
      case "STRIPE":
        cmds += BoldOn + PAYMENT_METHOD_STRIPE;
        break;
      case "CASH":
        cmds += BoldOn + PAYMENT_METHOD_CASH;
        break;
      case "CARD":
        cmds += BoldOn + PAYMENT_METHOD_CARD;
        break;
      default:
        break;
    }

    cmds += BoldOff + doubleTab + date + doubleTab + time.substring(0, 8);
    cmds += newLine + CenterOn + DIVIDER + newLine;

    if (IsNotEmpty(orderData.customer_name))
      cmds += FitToSize(orderData.customer_name.latinise(), 42) + newLine;
    if (IsNotEmpty(orderData.customer_phone))
      cmds += FitToSize(orderData.customer_phone.latinise(), 42) + newLine;

    if (IsNotEmpty(orderData.customer_address))
      cmds +=
        BoldOn + orderData.customer_address.latinise() + BoldOff + newLine;

    cmds += CenterOn + DIVIDER + newLine + CenterOff;

    return cmds;
  }

  //>> Table orders
  cmds += CenterOn + BoldOn;
  if (invoiceId != null) cmds += FitToSize("FS: " + invoiceId, 19);
  else cmds += FitToSize("Prefactura", 19);
  cmds += BoldOff;
  cmds += date + " " + time.substring(0, 8);

  cmds +=
    newLine +
    BoldOn +
    FitToSize("P: " + internalId + "    " + type.toUpperCase(), 25);
  cmds += BoldOff;
  if (IsNotEmpty(peopleNumber))
    cmds += FitToSize(peopleNumber + " pers.", 12, "START");

  cmds += newLine + DIVIDER + newLine + CenterOff;

  return cmds;
}

/**
 * Generates ESCPOS commands for a product in an invoice
 * @param {Object} product The object of the product
 * @returns ESCPOS commands for printing a product
 */
export function InvoiceProduct(product) {
  let cmds = "" + CenterOn;

  if (product == null || product == undefined) return cmds;
  const charNum = MaxTextLength();

  // Name
  let name = product.name.toUpperCase().latinise();

  // Atributes
  const price = parseFloat(product.price + "");
  const amount = parseInt(product.amount + "");
  const observation = product.observation;
  const attributes = product.attributes;
  const total_price = (product.amount * price).toFixed(2);

  let fmtAmount = amount + "";

  cmds += GetFontSize(FitToSize(fmtAmount, 4));
  cmds += GetFontSize(FitToSize(name, charNum));
  cmds += FitToSize(price.toFixed(2), 7, "START") + EURO;
  cmds += FitToSize(total_price, 7, "START") + EURO;

  cmds += newLine;

  if (IsNotEmpty(observation) && PrintComments()) {
    cmds += FitToSize(GetFontSize(observation.latinise()), 36);
    cmds += newLine;
  }

  if (IsNotEmpty(attributes) && PrintAttributes()) {
    cmds += FitToSize(GetFontSize(attributes.latinise()), 36);
    cmds += newLine;
  }

  // Line height
  for (let w = 0; w < parseInt(LineHeight()); w++) {
    cmds += newLine;
  }

  cmds += CenterOff + NormalFontSize;

  return cmds;
}

/**
 *
 * @param Object product
 * @returns
 */
export function InvoiceMenuProduct(product) {
  let cmds = "" + CenterOn;

  if (product == null || product == undefined) return cmds;

  // Name
  let name = product.name.toUpperCase().latinise(); // + product.price;

  // Atributes
  const amount = parseInt(product.amount + "");
  const observation = product.observation;
  const attributes = product.attributes;
  let fmtAmount = amount + "";

  cmds += GetFontSize(FitToSize(fmtAmount, 3));
  cmds += GetFontSize(FitToSize(name, 30));

  cmds += newLine;

  if (IsNotEmpty(observation) && PrintComments()) {
    cmds += FitToSize(GetFontSize(observation.latinise()), 36);
    cmds += newLine;
  }

  if (IsNotEmpty(attributes) && PrintAttributes()) {
    cmds += FitToSize(GetFontSize(attributes.latinise()), 36);
    cmds += newLine;
  }

  // cmds += doubleTab + doubleTab;
  // cmds +=
  //   TinyFontSize +
  //   observation
  //     .replaceAll("BREAKLINE", newLine + doubleTab + doubleTab)
  //     .latinise();

  // cmds += newLine + NormalFontSize;

  // Line height
  for (let w = 0; w < parseInt(LineHeight()); w++) {
    cmds += newLine;
  }

  cmds += CenterOff + NormalFontSize;

  return cmds;
}

/**
 * Generates ESCPOS commands for a menu in an invoice
 * @param {Object} menu
 * @param {Array} products
 * @returns ESCPOS commands for a menu
 */
export function InvoiceMenuESCPOS(menu, products) {
  if (menu == null || menu == undefined) return "";
  const charNum = MaxTextLength();

  let cmds = "" + CenterOn;

  // Atributes
  const price = parseFloat(menu.price + "");
  let name = menu.name.toUpperCase().latinise();

  cmds += GetFontSize(FitToSize("1", 4));
  cmds += GetFontSize(FitToSize(name, charNum));
  cmds += FitToSize(price.toFixed(2), 7, "START") + EURO;
  cmds += FitToSize(price.toFixed(2), 7, "START") + EURO;
  cmds += newLine;

  // Print products
  if (PrintMenuProducts())
    for (let j = 0; j < products.length; j++)
      cmds += InvoiceMenuProduct(menu.products[j]);

  return cmds;
}

export function InvoiceESCPOS(
  date,
  time,
  invoiceId,
  internalId,
  totalPrice,
  products,
  menus,
  type,
  peopleNumber,
  comment,
  customer,
  orderData,
  order,
  _partial = false,
  _partialAmount = 0,
  _partialPaymentMethod = ""
) {
  var cmds = esc + "@";

  cmds += InvoiceHeaderESCPOS(
    date,
    time,
    invoiceId,
    internalId,
    type,
    peopleNumber,
    customer,
    orderData
  );

  let printed_categories = [];

  for (let n = 0; n < products.length; n++) {
    const p = products[n];
    if (
      p.category &&
      printed_categories.findIndex((pc) => pc == p.category) == -1 &&
      PrintInvoiceCategory()
    ) {
      cmds += BoldOn + "  " + p.category.latinise().toUpperCase() + BoldOff;
      cmds += newLine;
      cmds += InvoiceProduct(p, true);
      cmds += CategoryProducts(
        p.category,
        products.slice(n + 1, products.length)
      );
      cmds += newLine;
      printed_categories.push(p.category);
    } else if (printed_categories.findIndex((pc) => pc == p.category) == -1) {
      cmds += InvoiceProduct(p, true);
    }
  }

  // Menus
  if (PrintInvoiceCategory() && menus.length > 0)
    cmds += BoldOn + "  MENUS" + BoldOff + newLine;
  for (let n = 0; n < menus.length; n++) {
    cmds += InvoiceMenuESCPOS(menus[n], menus[n].products);
  }

  if (ReadParameter(P.supplement_delivery) != "" && type == "Reparto") {
    cmds += newLine + CenterOn;
    cmds += BoldOn + FitToSize("Gastos de envio", 35);
    cmds +=
      doubleTab +
      parseFloat(ReadParameter(P.supplement_delivery)).toFixed(2) +
      EURO +
      BoldOff;
    cmds += newLine + CenterOff;
  }

  cmds += CenterOn + DIVIDER + newLine + CenterOff;
  cmds += _partial
    ? InvoicePartialFooter(totalPrice, _partialAmount, _partialPaymentMethod)
    : InvoiceFooter(totalPrice, order);
  cmds += newLine;

  return cmds;
}
