/**
 * Catálogo de servicios de backend para tareas de autenticación
 */
import {
  LoadBusinessData,
  LoadParameters,
  LoadPrintingParameters,
  SaveSpacesCache,
  SetSlug,
} from "../../business/ParametersController";
import {
  METHODS,
  defaultHeaders,
  RESPONSE_CODES,
  API_ENDPOINT,
  FetchResponseType,
} from "../../constants";
import { LoginResponse, PinCheckResponse } from "../ResponseModel";

const enum RESOURCES {
  login = "login",
  logout = "logout",
  recover_password = "recover_password",
  restaurants = "restaurants",
}

export function setSessionToken(
  session_token: string,
  remember: boolean
): void {
  if (remember) localStorage.setItem("session_token", session_token);
  sessionStorage.setItem("session_token", session_token);
}

export function getSessionToken(): string {
  return getCookie("fd-token");
}

export function getUserIdCookie(): string {
  return getCookie("fd-user_id");
}

export const authHeaders = () => {
  return {
    ...defaultHeaders,
    "FDS-Auth": getCookie("fd-token"),
    "FDS-User-id": getUserIdCookie(),
  };
};

/**
 * Get the value of a cookie
 * Source: https://gist.github.com/wpsmith/6cf23551dd140fb72ae7
 * @param  {string} name  The name of the cookie
 * @return {string}       The cookie value
 */
export function getCookie(name: string): string {
  let value = `; ${document.cookie}`;
  let parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
}

/**
 * Solicitud de inicio de sesión con credenciales y persistencia de sesión
 * @param login
 * @param password
 * @param remember
 * @returns
 */
export const LoginAttempt = async (
  login: string,
  password: string,
  remember: boolean
) => {
  //>> Composición de URL
  const resource = RESOURCES.login;
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResponseType<LoginResponse>;
  let DefaultResponse: LoginResponse;

  const result = await fetch(url, {
    method: METHODS.POST,
    headers: defaultHeaders,
    body: JSON.stringify({
      login: login,
      password: password,
    }),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code == RESPONSE_CODES.SUCCESS) {
        setSessionToken(res.response.session_token, remember);
        document.cookie = `fd-token=${
          res.response.session_token
        };path=/;max-age=${60 * 60 * 24 * 14};`;
        LoadParameters(
          res.response.parameters,
          res.response.reservation_parameters?.enable_reservation
        );
        LoadBusinessData(
          res.response.name,
          res.response.email,
          res.response.phone,
          res.response.cif,
          res.response.address,
          res.response.legal_name,
          res.response.city,
          res.response.region,
          res.response.zip_code
        );
        SetSlug(res.response.slug);
        SaveSpacesCache(res.response.spaces);
        LoadPrintingParameters(res.response.printing_parameters);
      }

      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

/**
 *
 * @param user_id
 * @param pin
 * @returns
 */
export const CheckUserPin = async (user_id: string | number, pin: string) => {
  //>> Composición de URL
  const resource = RESOURCES.login + "/user";
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResponseType<PinCheckResponse>;
  let DefaultResponse: PinCheckResponse;

  const result = await fetch(url, {
    method: METHODS.POST,
    headers: defaultHeaders,
    body: JSON.stringify({
      user_id: user_id,
      pin: pin,
    }),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code === RESPONSE_CODES.SUCCESS)
        document.cookie = `fd-user_id=${res.response.id};path=/;max-age=${
          60 * 60 * 24 * 14
        };`;

      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

/**
 * Solicitud de recuperación de contraseña
 * @param email
 */
export const PasswordRecoveryService = async (email: string) => {
  //>> Composición de URL
  const resource = RESOURCES.recover_password;
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResponseType<null>;
  let DefaultResponse: null = null;

  const result = await fetch(url, {
    method: "POST",
    headers: defaultHeaders,
    body: JSON.stringify({
      email: email,
    }),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

/**
 */
export const Signup = async (
  name: String,
  phone: String,
  email: String,
  address: String,
  zip_code: String,
  password: String,
  slug: String,
  auth_id: String,
  stripe_id: String,
  login: String
) => {
  //>> Composición de URL
  const resource = RESOURCES.restaurants;
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResponseType<null>;
  let DefaultResponse: string = "";

  const result = await fetch(url, {
    method: "POST",
    headers: defaultHeaders,
    body: JSON.stringify({
      name: name,
      phone: phone,
      email: email,
      address: address,
      zip_code: zip_code,
      password: password,
      slug: slug,
      auth_id: auth_id,
      stripe_id: stripe_id,
      login: login,
    }),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};
